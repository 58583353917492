






import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import DemoShowBlock from './components/DemoShowBlock.vue'

@Component({
  components: {
    DemoShowBlock
  }
})
export default class DemoShow extends Vue {

  get isH5() {
    return AppModule.isH5
  }
}
